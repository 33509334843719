<template>
  <div class="needs">
    <search-header></search-header>
    <div class="container p-0 pt-3 pb-3 ">
      <div class="row m-0">
            <div class="col-12 bg-white rounded p-0">
                <div class="border-bottom p-3">测绘项目</div>
                <div class="container p-0">
                    <div class="row pb-3 m-0">
                        <div class="col-12 p-0 d-flex flex-column py-3 align-items-center justify-content-center">
                            <div>
                                <div class="clearfix mt-3">
                                    <div class="float-left width-100 text-right mt-2">
                                        测绘工具：
                                    </div>
                                    <div class="float-left">
                                        <dropdown :datas="tools" :active="toolsId" @onChange="onToolsChange" :width="220" placeholder="选择测绘工具"></dropdown>
                                    </div>
                                </div>
                                <div class="clearfix mt-3">
                                    <div class="float-left width-100 text-right mt-2">
                                        测绘地点：
                                    </div>
                                    <div class="float-left areabox box">
                                        <input @click.stop="isAreaShow = !isAreaShow" :value="areaValue"  class="form-control" type="text" placeholder="城市选择" readonly="readonly">
                                        <em>
                                            <b-icon icon="chevron-down"></b-icon>
                                        </em>
                                        <city v-model="isAreaShow" @onChoose="onChooseArea"></city>
                                    </div>
                                </div>
                                <div class="clearfix mt-3">
                                    <div class="float-left width-100 text-right mt-2">
                                        测绘时间：
                                    </div>
                                    <div class="float-left d-flex align-items-center">
                                        <date-picker v-model="para.startTime" :disabled-date="notBeforeToday" :editable="false" :show-second="false" input-class="form-control width-180" type="date" style="width: 180px;"></date-picker>
                                        <span class="mx-2">-</span> 
                                        <date-picker v-model="para.endTime" :disabled-date="notBeforeToday" :editable="false" :show-second="false" input-class="form-control width-180" type="date" style="width: 180px;"></date-picker>
                                    </div>
                                </div>
                                <div class="clearfix mt-3">
                                    <div class="float-left width-100 text-right mt-2">
                                        联系人：
                                    </div>
                                    <div class="float-left">
                                        <input v-model="para.contacts" class="form-control width-380" maxlength="10" type="text">
                                    </div>
                                </div>
                                <div class="clearfix mt-3">
                                    <div class="float-left width-100 text-right mt-2">
                                        联系方式：
                                    </div>
                                    <div class="float-left">
                                        <input v-model="para.contactNumber" class="form-control width-380" maxlength="11" type="text">
                                    </div>
                                </div>
                                <b-button variant="success mt-3 width-160" style="margin-left:100px">立即预约</b-button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import city from "@/components/city/city.vue";   
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
export default {
    components: {
        city, datePicker
    },
     data() {
        return {
            toolsId: '',
            isAreaShow:false,
            areaValue: '',
            tools:[{
                id: 1,
                text: '航飞测量'
            },{
                id: 2,
                text: '土地平整'
            },{
                id: 3,
                text: '飞防'
            },{
                id: 4,
                text: '松害线虫病防控'
            },{
                id: 5,
                text: '高标准农田'
            },{
                id: 6,
                text: '其他测绘项目'
            }],
            para: {
                classId: '',
                area: '',
                class: '',
                startTime: '',
                endTime: '',
                contacts: '',
                contactNumber: ''
            }
        };
    },
    methods: {
        onChooseArea(a) { 
            this.areaValue = a.province.name + (a.city.name == '市辖区' ? '' : ' ' + a.city.name ) + ' ' + a.area.name;
            this.para.area = a;
            this.isAreaShow = false;
        },
        onToolsChange(t) {
            this.para.classId = t.id;
        },
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        }
    },
    created() {
        if(this.$route.query.name) {
            let m = this.tools.find(x=>x.text == this.$route.query.name);
            if(m) {
                this.toolsId = m.id;
            }
        }
    }
};
</script>

<style scoped>
    .needs {
        background-color: #f5f5f5;
    }
</style>
